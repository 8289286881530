
/************************************
*	UŽIVATELSKY SESTAVENÝ FORMULÁŘ
************************************/

.formular {
	#formular & {
		padding: 0 36px;
		margin-top: 20px;
	}

	.lblok,
	.pblok {
		@extend %border-box;
		clear: none;
		padding: 5px 3%;
	}
	
	.lblok {
		margin-right: 3%;
		float: left;
		text-align: right;
		padding-right: 0;

		label {
			font-weight: 700;
			font-size: rem(16px);
		}

		span {
			font-weight: 400;
			font-size: rem(14px);
		}
	}

	.pblok {
		float: right;
		padding-left: 0;
		padding-right: 0;
	}

	.lblok:empty,
	.lblok[style="width:1%;"] {
		display: none;

		+ .pblok {
			width: 100% !important;
			padding-left: 3%;
			padding-right: 3%;
		}
	}

	@include bp("< 520px") {
		.lblok,
		.pblok {
			float: none;
			width: auto !important;
			margin: 0;
			padding: 5px 3%;
			text-align: left;
		}
	} 

	.format[type="file"] {
		padding-left: 0;
		padding-top: 0;
	}

	.lblok input,
	.pblok .fradio,
	input[type="file"],
	.btn {
		width: auto;
	}

	.lblok [type="checkbox"] {
		margin-top: .5em;
	}

	.b1,
	.b2 {
		width: auto;
		min-width: 300px;
		padding: 15px 0;

		&.last {
			text-align: center;
			padding: 15px 0;

			.lblok,
			.pblok {
				float: none;
			}

			.fbtn2 {
				display: inline-block;
				float: none;
			}
		}
	}
	
	.odkazy {
		padding-left: 10px;
	}
	
	hr.oddelovac {
		height: 4px;
	}
	
	fieldset {
		//margin: 0 8px 6px 8px;
		//padding: 0 0 5px 0;

		label,
		span {
			font-weight: normal;
		}
		
		span {
			display: block;
			margin: 4px 6px;
		}
	}
	
	label {
		font-weight: normal;
	}

	.b1,
	.b2 {
		background-color: transparent;
		border-bottom: 2px #ffffff solid;
	}

/***********************************
* 20150811 - pro vytvářeč formulářů
***********************************/

	#elementsChoiceArea {
		@extend %reset;
		list-style-type: none;
		position: relative;
	
		.lblok {
			width: 35%;
		}

		.pblok {
			width: 55%;
		}

		.b1,
		.b2 {
			width: auto;
			min-width: 0px;
		}

		.overElm {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			background-color: transparent;
			top: 0;
			left: 0;
		}

		.pblok.null {
			padding-left: 10px;
		}

		.lblok.radiogroup {
			width: 0%;
		}

		li {
			position: relative;
		}
	}

	#definitionsArea.format {
		@extend %border-box;
		padding: 5px;
		margin: 10px 0;
		width: 95%;
		
		li {
			margin: 0;
			
			.lblok,
			.pblok {
				float: left;
				padding: 5px 2.5%;
				margin: 0;

				fieldset,
				.format {
					@extend %border-box;
					width: 100%;
				}
			}
			
			.lblok {
				width: 20%;
			}

			.pblok {
				width: 70%;
			}
			
			fieldset {
				margin: 0;
			}
		}
		
		.infoText {
			padding: 30px 15px;
			text-align: center;
			list-style-type: none;
		}
	}

	.lblok #elementsChoiceArea input[type=text],
	#elementsChoiceArea input[type=file] {
		width: 95%;
	}

	.lblok #elementsChoiceArea .pblok {
		text-align: left;
	}

	#create-form & {
		* {
			@include font-size(12px);
		}

		.lblok h3.cvi {
			@include font-size(20px);
			border-bottom: 1px rgba(0,0,0,.3) solid;
			padding: 3px;
			width: auto;
			margin-left: 0;
			font-weight: bold;
		}
	}
}

#create-form #userForm_InfoMessageArea {
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
}

.last {
	.lblok,
	.pblok {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}


form #skryvaci .fdatum {
	@extend %sf;

	+ .vyber-archiv {
		margin-top: 8px;

		br {
			display: none;
		}

		* {
			vertical-align: middle;
		}

		input[type="checkbox"],
		input[type="radio"] {
			margin-top: 0;
			margin-right: 5px;
		}
	}
}