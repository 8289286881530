
@import "vars";

.bodkazy {
	.ui li {
		line-height: 1.3;
		padding: 1px 0;
		margin: 0 0 .5em 0;

		.hodnota li {
			background-image: none;
			padding: 0;
		}
	}

	.odkazy .ui li::before {
		left: 5px;
	}
}



/*******************************
* PŘEHLED OSOB
*******************************/

.dok.osoby {
	li {
		padding-left: 146px;

		img {
			margin-left: -146px;
			max-width: 120px;
		}

		&.noface {
			&::before {
				content: "";
				margin-left: -146px;
				float: left;
				width: 120px;
				height: 120px;
				background: #cccccc url(/aspinclude/vismoweb5/html/noface.svg) no-repeat 50% 50%;
				background-size: 80px auto;
			}
		}

		@include bp("< 420px") {
			padding-left: 0;

			strong img,
			&.noface::before {
				float: none;
				display: table;
				margin: 0 0 10px 0;
			}
		}
	}
}

// .bodkazy ul.ui li.u,
// .galerie ul.ui li.u {
// 	padding: 4px 0 2px 20px;
// }  
// padding-left musi byt 20 kvuli ikonam typu souboru

//$pripony: bmp doc docx fla ftg gif htm html jpg png mdb mp3 wav wma ogg msg pdf odt wmv mp4 avi exe mht tif ppt pptx rar rtf swf txt unk xls xlsx xml zip dwg;

// .bodkazy .ui {
// 	@each $pripona in ( 602, bmp, doc, docx, fla, ftg, gif, htm, jpg, mdb, mp3, msg, pdf, ppt, pptx, rar, rtf, swf, txt, unk, xls, xlsx, xml, zip, dwg ) {
// 		.t#{$pripona} {
// 			background-image: url(#{$cestaIkony}#{$pripona}.gif);
// 		}
// 	}
// }

// .bodkazy .ui {
// 	@each $pripona in $pripony {
// 		.t#{$pripona} {
// 			background-image: url(#{$cestaIkony}svg/#{$pripona}.svg);
// 		}
// 	}
// }