
.format {
	transition: border-color .15s ease-out;
	border-radius: 0;
	-webkit-border-radius: 0;
	box-shadow: inset 0 0 0 black;
	-webkit-box-shadow: inset 0 0 0 black;
	width: 100%;
	background-image: none;
	padding: 0 8px;
	margin: .25em 0 0 0;

	&:focus {
		box-shadow: inset 0 0 0 black;
		-webkit-box-shadow: inset 0 0 0 black;
		outline: 0;
	}
}

input[type="text"].format {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

input[type="file"].format {
	padding-left: 0;
}	

input[type="radio"],
input[type="checkbox"] {
	vertical-align: middle;
	margin: 0 8px 0 0;
}

textarea.format {
	resize: vertical;
	padding: 8px;
}

select.format,
select.format:focus {
	padding-right: 25px;
	-webkit-appearance: none; 
	-moz-appearance: none; 
	appearance: none;
	background: {
		image: encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 15 15" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z"/></svg>');
		position: 100% 50%;
		repeat: no-repeat;
	}
}

select.inline-form-select::-ms-expand,
select.format::-ms-expand {
	display: none;
}

select.format[multiple] {
	background-image: none;
}


/****************************
* ZÁLOŽKOVÉ RADIO-BUTTONY
****************************/

.ftabs {
	display: inline-table;
	border: 1px #404040 solid;
	border-radius: 3px;
	margin: 0 20px 8px 10px;
	font-size: 0;

	label {
		display: inline;
		margin: 0;
	}

	input[type="radio"] {
		position: absolute;
		left: -9999em;

		+ span {
			transition: background-color .2s ease-out, color .2s ease-out;
			cursor: pointer;
			display: table-cell;
			padding: 3px 1em;
			background-color: rgba(#000000, 0);
		}

		&:checked + span {
			cursor: default;
			background-color: #404040;
			color: #ffffff;
		}
	}
}

/***************************/


/****************************
* POLE S DATEPICKEREM
****************************/
*
#dd,
#do,
input#datum {
	padding-right: 42px;
	background: {
		image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
		repeat: no-repeat;
		position: 100% 30%;
	}
    + .calendar-toggle{
        padding: 0;
        margin: 5px 0 0;
        border: 0;
        position: absolute;
        right: 37px;
        background-color: transparent;
    }
}

/***************************/


/****************************
* BĚŽNÉ FORMULÁŘE
****************************/

.fvlevo,
.fvpravo {
	@extend %sf;
	margin: 0 0 1em 0;
}

.fvlevo:nth-last-child(2),
.fvpravo:last-child {
	margin-bottom: 0;
}

.nizky .fvlevo:nth-last-child(2) {
	margin-bottom: 1em;
}

@include bp("> 500px") {
	.fvlevo,
	.fvpravo {
		width: 48.5%;
	}

	.fvlevo {
		float: left;
	}

	.fvpravo {
		float: right;
	}
}

.fkont {
	a:not([href]) {
		text-decoration: none !important;
		line-height: 0;
	}

	form {
		@extend %border-box;
		padding: 15px 20px 25px 20px;
		margin: 0 0 20px 0;
		position: relative;
	}

	label {
		line-height: 1.45;
		font-weight: normal;
		margin-bottom: .25em;
	}

	#prepinac {
		float: left;
		margin: .45em 1em 1em 0;

		@include bp("> 500px") {
			margin-bottom: 6px;
		}

		a {
			text-decoration: underline;
		}
	}

	.fvyraz,
	.fpredkladatel,
	.fcheckbox,
	fieldset {
		@extend %sf;
		margin-bottom: 1em;//.5em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	fieldset {
		border: 0;
		padding: 0;
	}

	.fcheckbox + .fcheckbox,
	.fcheckbox + [type="hidden"] + .fcheckbox {
		margin-top: -.5em;
	}

	#search:empty {
		text-decoration: none !important;
		line-height: 0;
		font-size: 0;
	}

	.format,
	select,
	label {
		clear: both;
	}
}

.nizky {
	width: 100%;

	.sf {
		display: none;
	}

	.fvlevo,
	.fvpravo {
		width: auto;
		float: none;
	}

	@include bp("> 580px") {
		form {
			width: 100%;
			display: table;
			table-layout: fixed;
		}

		.fvlevo {
			padding-right: 4%;
		}

		.fvlevo,
		.fbtn,
		.fvpravo {
			float: none;
			display: table-cell;
			vertical-align: bottom;
		}

		.fbtn,
		.fvpravo {
			width: 10em;
		}
	}
}

.fbtn {
	text-align: right;
	display: inline;
	float: right;
	white-space: nowrap;
	margin-left: 15px;
}

.fbtn div {
	float: right;
}

.vyhlodkaz {
	margin: 0 0 .5em 0;
	padding: 0;
}


/**********************
* OSTATNÍ TEXTY
**********************/

form input,
form textarea,
form button,
form label,
form select {
	font-size: rem(15px);
}

