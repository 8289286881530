/*pro soubory o_osoba.asp a o_utvar.asp a galerie2.asp*/

#utvar .odkazy ul,
#osobnost .odkazy ul {
	margin: 10px 0;
}

// osobnost
.vip {
	float: right;
	position: relative;
	z-index: 10;
	display: inline;
	margin: 0;
	padding: 0 10px 10px 10px;
	
	img {
		margin:0 0 8px 0;
	}

	strong {
		display: block;
		margin-bottom: 4px;
	}
}

@include bp("< 580px") {
	.vip {
		float: none;
		display: block;
		width: auto !important;
		margin-bottom: 25px;
	}

	#kvlevo {
		margin-right: 0 !important;
	}
}

.obsah h3 {
	clear: left!important;
}

// utvar
// .utvarkontakt {
// 	width: 100%;
// 	padding-top: 1px;
// }

dl.kontakty {
	width: 100%;
	margin: 25px 0 0 0;
	
	ul {
		margin-left:20px;

		ul {
			margin-left: 0;
		}
	}

	dd {
		padding-left: 0;
	}
}

hr.cist {
	display: inline;
}

.ddDen {
	display: block;
	float: left;
	width: 4.5em;
}

dd .mapa {
	position: absolute !important;
	max-width: 100%;

	div {
		margin-top: 0;
	}
}

.mapstop {
	margin-top: 300px !important;
}
