/*pro vsechny stranky s tabulkou (.seznam - prehled dokumentu, osob, utvaru, anketa, pruvodka, fulltext, ke stažení)*/

.seznam {
	border-collapse: collapse;
	empty-cells: show;
	margin: 0 auto;
	width: 100%;

	td,
	th {
		vertical-align: top;
		padding: 6px 10px 7px 10px;

	  &:first-child {
	  	padding-left: 18px;
	  }
	}
	
	th {
		font-weight: bold;
		text-align: left;
		white-space: nowrap;
		padding-top: 8px;

		&.tddatum {
			width: 10%;
		}
		
		&.tdzodpovida {
			width: 20%;
		}
	}

	td img {
		float: left;
		border: 0;
		margin: 6px 8px 2px 0;
	}
  
  td div {
  	margin-top:2px;
  }
}

.cislo {
	white-space: nowrap;
}

.blokposun {
	width: 100%;
	overflow: auto;
	max-width: 100%;
	margin: 10px 0;
}

form.vyber {
	margin: 0;
	padding: 0 0 10px 0;
	border: 0;

	label {
		margin-left: 4px;
		margin-right: 30px;
	}
	
	input {
		vertical-align: middle;
	}
}

.nadpissekce {
	margin: 10px 0 15px 0;
	padding: 0;
}

.a,
.b,
.c {
	float: left;
	display: inline;
	width: 30%;
}

table.seznam ul {
	margin: 0 0 0 14px;
	padding: 1px;
}

// Telefonní seznam

.vypis-pismena {
	padding: 0 0 20px 0;
	margin: 0;
	font-weight: bold;

	li {
		background-image: none;
		border: 0!important;

		&::before {
			color: #888888;
			font-weight: normal;
		}

		a {
			text-decoration: underline;
			margin: 0;
		}

		* {
			line-height: normal;
		}
	}
}

// #seznam .fvyraz {
// 	label {
// 		display: block;
// 	}

// 	.format {
// 		//width: 100%;
// 		//max-width: 100%;
// 		clear: none;
// 		font-family: inherit!important;
// 		padding-left: 5px;
// 		padding-right: 2px;
// 	}

// 	select.format {
// 		padding-bottom: 1px;
// 		padding-top: 1px;
// 	}

// 	@include bp("> 600px") {
// 		label {
// 			width: 10em;
// 			float: left !important;
// 		}

// 		.format {
// 			max-width: 70%;
// 			float: right !important;
// 		}
// 	}
// }

// Záložky u veřejných zakázek
.vzzalozky {
	padding: 15px 0 0 0;
	width: 100%;

	ul {
		@extend %reset-list;
		padding-bottom: 1px;
		font-weight: bold;
		text-transform: uppercase;
	}

	li {
		@extend %reset;
		float: left;
		position: relative;
		width: 50%;
		display: block;
		text-align: center;

		span,
		a {
			display: block;
			list-style-type: none;
			margin: 0;
			border: 2px black solid;
			border-width: 0 0 2px 0;
			padding: 12px 15px 7px 15px;
		}
		
		&.vzazalozka span {
			border-width: 2px 2px 0 2px;
		}
	}
}

.ezakazky .blokposun {
	margin-top: 0;
	padding-top: 4px;
}

#profilzadavatele {
	.zakazky {
		th.sirka {
			width: 19%;
			white-space: normal;
		}

		td {
			vertical-align: middle;
		}
	}

	.seznam th.tdzodpovida {
		width: 15%;
	}
}


// Font pro šipky v záhlaví tabulek
@font-face {
  font-family: "whinterni";
  //src:url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.eot");
  src: /*url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.eot?#iefix") format("embedded-opentype"),*/
       url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.woff") format("woff"),
       url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.ttf") format("truetype"),
       url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.svg#whinterni") format("svg");
  font-weight: normal;
  font-style: normal;
}

.sassSipka {
	font-family: "whinterni" !important;
	font-size: 14px;
	//*font-family: Arial,"Arial CE",Helvetica,sans-serif !important;

	&::before {
	  font: {
			family: "whinterni" !important;
			style: normal !important;
			weight: normal !important;
			variant: normal !important;
	  }

	  text-transform: none !important;
	  line-height: 1;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  display: inline-block;
	  margin: 0 .2em 0 0;
	  vertical-align: top;
	  vertical-align: text-top;
	  position: relative;
	  top: .1em;
	  text-rendering: geometricPrecision;
	}
}

.sassSipkaA {
	text-decoration: none !important;
}

.sassSipkaD::before {
	content: "\e000";
}

.sassSipkaDA::before {
	content: "\e001";
}

.sassSipkaN::before {
	content: "\e002";
}

.sassSipkaNA::before {
	content: "\e003";
}
