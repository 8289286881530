﻿/*pouze isvs*/

#isvs {
	dl {
		margin:6px 0 3px 0;
		padding:5px 5px 5px 18px;
	}

	.ui li {
		margin-top: 10px;
	}
	
	.hodnota {
		margin: 4px 0;
		padding: 5px 0 5px 27px;
		
		li {
			@extend %reset;
			line-height: 1.25;
			list-style-type: none;
			background-image: none;
		}

		div {
			padding-top: 0;
		}
	}

	.odsazena {
		padding: 0 0 0 27px;

		li {
			padding:5px 0 10px 0;
		}
	}
}


.isvsItemDescription {
	&,
	ul.ui li & {
		@include font-size(13px);
		font-style: italic;
		background-color: #D4D0C8;
		color: #101010;
		padding: 1em;
		margin: 20px 0 0 0;
		border: 1px #C0BCB4 solid;

		strong {
			font-weight: bold;
		}
	}

	p {
		margin: 0 0 1em 0;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	ol li {
		list-style-type: lower-alpha;
	}
	
	ul li {
		background-image: none;
	}

	ul li,
	ol li {
		background-image: none;
		padding-top: 0;
		margin-top: 0;
		padding-left: 0;

		&:before {
			content: normal;
		}
	}

	ol,
	ul {
		margin-top: 0;
		padding-top: 0;
		padding-left: 40px;
	}
}
